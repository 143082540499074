import { formatNumber, unformat } from 'accounting';

const getApiDomain = () => {

    if (window.location.hostname === "localhost" || window.location.hostname === "tci.finance.in") {
        return "http://tci.finance.in/api/v1/";
    } else if (window.location.hostname === "finance.tcisigorta.com.com") {
        return window.location.protocol + "//" + "financeservice.tcisigorta.com/api/v1/";
    }


    return window.location.protocol + "//" + "financeservice.tcisigorta.com/api/v1/";
    let serviceDomain = window.location.protocol + "//" + window.location.hostname + ":81/api/";
    return serviceDomain;
}

const getFetchOptions = () => {
    let userData = localStorage.userData;

    let final = ``;
    if (userData) {
        let userObject = JSON.parse(userData);
        let token = userObject.token;
        final = `Bearer ${token}`;
    };
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': final
        },
    };
    return options;
};

export const getNumeric = e => {
    const reg = /\D+/g
    e.target.value = e.target.value.replace(reg, '');
}

export const postExcelApiResult = (url, options) => {
    let fullUrl = `${getApiDomain()}${url}`;

    let finalOptions = { ...getFetchOptions(), ...options };

    fetch(fullUrl, finalOptions)
    .then(response => response.blob())
    .then(blob => {
        console.log(blob);
        const url2 = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute('download', 'data.xls');
        document.body.appendChild(link);
        link.click();
        link.remove()
    });
}


export const fetchAsync = async (url, options) => {

    if (!options) {
        options = {
            method: "GET",
        };
    }

    let finalOptions = { ...getFetchOptions(), ...options };

    let result = {
        isSuccess: true,
        data: null,
        error: null
    }

    let fullUrl = `${getApiDomain()}${url}`;

    try {
        const res = await fetch(fullUrl, finalOptions);
        const pathName = window.location.pathname;
        if (res.status === 401 && pathName !== "/" && pathName !== '/login') {
            window.location.href = "/";
        }

        const json = await res.json();

        if (res.status !== 200) {
            result.isSuccess = false;
            result.data = {
                message: json.message
            }
        }

        result.data = json;

    } catch (err) {
        result.isSuccess = false;
        result.data = {
            message: `Beklenmeyen durum olustu! => ${err || ''}`
        };
    }
    return result;
};

export const monthParameter = (month) => {

    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }];

    const result = months.find(item => item.value === month);
    return result;

}

export const monthArray = (date) => {
    date.setMonth(date.getMonth() - 4);
    const finalList = [];
    for (var i = 0; i < 7; i++) {
        date.setMonth(date.getMonth() + 1);
        finalList.push(getFilterDate(date));
    }
    return finalList;
}

export const monthOffsets = (date, less, more) => {
    const negOffset = -3 + less * 3;
    const pozOffset = 3 + more * 3;
    const diff = pozOffset - negOffset + 1;
    const finalList = [];

    date.setMonth(date.getMonth() + negOffset - 1);
    for (var i = 0; i < diff; i++) {
        date.setMonth(date.getMonth() + 1);
        finalList.push(getFilterDate(date));
    }
    return finalList;
}


const getFilterDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const displayName = monthParameter(month).label;
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const offset = new Date().getTimezoneOffset();
    return { year, month, displayName, firstDate, lastDate, offset };
}

export const onFocus = (txb) => {
    const value = unformat(txb.value.replace(/[^\0-9]/ig, ""));
    txb.value = value;
}

export const numericFilter = (txb) => {
    const value = txb.value.replace(/[^\0-9]/ig, "");
    txb.value = value;
}

export const onBlur = (txb, pre = 2) => {
    const value = unformat(txb.value.replace(/[^\0-9]/ig, ""));
    txb.value = formatNumber(value, pre);
}

export const selectValue = (selectItem) => {
    return (selectItem && (selectItem.value || selectItem.value === 0)) ? selectItem.value : (typeof selectItem !== 'object' ? selectItem : null);
}

export const getElementValue = (key) => {
    const elem = document.querySelector(key);
    return elem ? elem.value : '';
}

export const setElementValue = (key, value) => {
    const elem = document.querySelector(key);
    if (!elem) return;
    elem.value = value;
}